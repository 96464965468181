@import "../../../GlobalUtils/global-utils.scss";

.running-time-not-linked {
	.playhead-wrapper {
		.playhead-container {
			.timer-display {
				&.playhead-timer {
					color: $color-secondary;
				}
			}
		}
	}
}

.running-time-is-linked {
	.playhead-wrapper {
		.playhead-container {
			.timer-display {
				&.playhead-timer {
					color: $color-primary;
				}
			}
		}
	}
}

.playhead-wrapper {
	background: $color-text;
	color: $color-text-contrast;
	display: block;
	padding: 20px;
	overflow: hidden;

	@include media-min-w(1080px) {
		padding: 25px;
	}

	.playhead-container {
		display: flex;
		flex: 1 0 auto;
		flex-direction: column-reverse;

		@include media-min-w(576px) {
			flex-direction: row;
		}
		@include media-min-w(768px) {
			flex-direction: column-reverse;
		}
		@include media-min-w(1080px) {
			flex-direction: row;
		}
	}
	.timer-controls-wrapper {
		display: block;
		width: auto;
		max-width: 170px;
		margin: 0 auto;

		@include media-min-w(576px) {
			margin: 0 20px 0 0;
		}
		@include media-min-w(768px) {
			margin: 0 auto;
		}
		@include media-min-w(1080px) {
			margin: 0 20px 0 0;
		}

		.timer-controls-container {
			button {
				&.edit,
				&.switch {
					width: 40px;
					height: 40px;
				}

				&.play {
					margin: 0 15px;
					width: 60px;
					height: 60px;
					&.not-playing {
						background-position: 19px center;
					}
				}
			}
		}
	}

	.playhead-info-wrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;
		flex: 1 1 auto;
		width: 100%;
		text-align: center;
		margin-bottom: 10px;

		@include media-min-w(576px) {
			width: calc(100% - 190px);
			text-align: left;
			margin-bottom: 0px;
		}
		@include media-min-w(768px) {
			width: 100%;
			text-align: center;
			margin-bottom: 10px;
		}
		@include media-min-w(1080px) {
			width: calc(100% - 190px);
			text-align: left;
			margin-bottom: 0px;
		}

		.playhead-task-info {
			@include font-size(18);
			font-weight: 700;
			color: $color-text-contrast;
			margin-bottom: 0;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			margin-bottom: 6px;
			.task-name,
			.task-name .group-name-label {
				position: relative;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
			}
			.task-name {
				width: 100%;
				.group-name-label {
					width: 100%;
					line-height: 1;
				}
			}
			.group-indicator-no-icon + .task-name {
				top: 0;
			}
		}
		.timer-display {
			&.playhead-timer {
				display: block;
				position: relative;
				font-family: $font-display;
				line-height: 1;

				color: $color-text-contrast;
				font-family: $font-display;
				display: inline-block;
				text-align: center;
				@include media-min-w(576px) {
					text-align: left;
				}
				@include media-min-w(768px) {
					text-align: center;
				}
				@include media-min-w(1080px) {
					text-align: left;
				}

				.time-indicator,
				.time-unit-indicator {
					display: inline-block;
					text-align: center;
					line-height: 1;
				}

				.time-indicator {
					font-weight: 500;
					@include mono-font-size(32);
				}

				.time-unit-indicator {
					font-weight: 400;
					margin-right: 6px;
					margin-left: 2px;
					@include font-size(19);
					&:last-of-type {
						margin-right: 0;
					}
				}
			}
		}
	}
}

@media screen and (min-width: 768px) {
	.playhead-wrapper {
		grid-row-start: 3;
		grid-row-end: 4;
		grid-column-start: 2;
		grid-column-end: 3;
	}
}
