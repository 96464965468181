@import "../../../GlobalUtils/global-utils.scss";

#comments-view-controls-header {
	width: 100%;
	max-width: 800px;
}

.toggle-wrapper {
	button {
		&#orderby-time {
			background-image: url("../../assets/svg/task-icon-history-blue-outline.svg");
			&.active {
				background-image: url("../../assets/svg/task-icon-history-white-outline.svg");
			}
		}
		&#orderby-group {
			background-image: url("../../assets/svg/orderby-a-z-desc-blue-outline.svg");
			&.active {
				background-image: url("../../assets/svg/orderby-a-z-desc-white-outline.svg");
			}
		}
	}
}

.add-time-entry {
	position: relative;
	flex-shrink: 0;
	right: 0;
	background-image: url("../../assets/svg/plus-white.svg");
	background-size: 20px;
	background-repeat: no-repeat;
	background-position: center;
	box-shadow: 0px 2px 4px 2px rgba($color-text, 0.15);
	@include media-min-w(768px) {
		position: fixed;
		bottom: 100px;
		right: 380px;
	}
	@include media-min-w(1080px) {
		right: 530px;
	}
	@include media-min-w(1080px) {
		right: calc(530px);
	}
	@include media-min-w(1500px) {
		left: calc(50% - 250px + 400px);
	}
	@include media-min-w(1600px) {
		left: calc(50% - 250px + 450px);
	}
}
