@import "../../GlobalUtils/global-utils.scss";

#comments-view-wrapper {
	grid-row-start: 2;
	grid-row-end: 3;
	grid-column-start: 1;
	grid-column-end: 2;
	display: flex;
	align-items: center;
	flex-direction: column;
	padding: 30px 30px 30px 15px;
	overflow-y: scroll;

	@media (max-width: 768px) {
		overflow-y: visible;
	}
}

#comments-view-content-wrapper {
	position: relative;
	width: 100%;
	max-width: 800px;

	/* This bottom padding is to stop the big plus button covering the menu of the last time entry */
	padding-bottom: 100px;
}

.timeline-notes-wrapper {
	.timeline-notes-container {
		height: 100%;
		position: relative;
		display: block;
		overflow: visible;
		@include transition(all 0.3s);

		.group-summary {
			width: 100%;
			//   margin-top: 30px;
		}
	}
}
