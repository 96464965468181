.log-in-screen {
	grid-row-start: 2;
	grid-row-end: 4;
	grid-column-start: 1;
	grid-column-end: 3;
	display: flex;
	justify-content: center;
	align-items: center;
}

.log-in-screen .header {
	position: relative;
	padding: 80px 0;
	font-size: calc(40px + 3vmin);
	background-color: #282c34;
	color: white;
}

.log-in-screen .log-in-form {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.log-in-screen .log-in-form .username-text-box {
	padding: 10px;
	margin: 10px;
	margin-left: 50px;
	margin-right: 50px;
}

.log-in-screen .log-in-form .password-text-box {
	padding: 10px;
	margin: 10px;
	margin-left: 50px;
	margin-right: 50px;
}

.log-in-screen .log-in-form .log-in-title {
	font-size: 18pt;
	color: white;
}

.log-in-screen .log-in-form .log-in-button {
	margin-top: 10px;
}

.log-in-screen .log-in-form .failed-log-in-label {
	color: red;
	padding: 10px;
}

.log-in-screen .log-in-form .cheaty-boi {
	padding: 0;
	margin-left: 30px;
	margin-top: 10px;
	width: 80px;
	height: 30px;
	background-color: lightgrey;
}
