@import "../../../GlobalStyles/global-styles.scss";

.comment-wrapper {
	display: flex;
	flex-direction: column;
	flex: 1;
	margin: 10px 6px;
	justify-content: center;
	@include media-min-w(567px) {
		margin: 10px 12px;
	}

	&.is-editing {
		.editable-content-wrapper {
			.editable-content-container {
				textarea {
					overflow-y: auto;
				}
				// justify-content: flex-start;
			}
		}
	}

	.comment {
		// This is for all styling that needs to be the same between the shadow-comment and the textarea
		border: 0;
		background-color: $color-text-contrast;
		color: $color-text;
		@include font-size(18);
		line-height: 1.5;
		font-weight: 400;
		padding: 0px;
		box-sizing: border-box;
		margin: 0;
	}

	textarea {
		position: relative;
		width: 100%;
		border: 0;
		background-color: $color-text-contrast;
		color: $color-text-contrast;
		@include font-size(18);
		line-height: 1.5;
		font-weight: 400;
		padding: 0px;
		box-sizing: border-box;
		margin: 0;
		z-index: 9;
		border-radius: 6px;
		outline: 0;
		box-shadow: none;
		transition: all 0.3s;
		&:focus,
		&:active,
		&active:focus {
			overflow-y: auto;
		}
		@include interactive-states() {
			outline: 0;
			box-shadow: none;
		}
		resize: none;
	}
}
