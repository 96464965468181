@import "../../../GlobalUtils/global-utils.scss";

.group-indicator-wrapper {
	.time-unit-summary-group-indicator {
		position: absolute;
		right: 0;
		height: 100%;
		width: 60px;
		border-radius: 40px;
		z-index: 1;
		padding-left: 10px;
		background-repeat: no-repeat;
		background-position: left center;
	}
}

.group-header-wrapper + .time-entry-summary {
	margin-top: 0;
	padding-top: 0;
	border-top: 0;
}

.time-entry-summary {
	@include flex-display();
	@include flex-direction(row);
	position: relative;
	min-height: 85px;
	margin-top: 15px;
	padding-top: 15px;
	color: $color-text;
	border-top: 1px solid rgba($color-text, 0.15);
	background-color: transparent;
	margin-left: 0px;
	@include media-min-w(480px) {
		margin-left: 65px;
	}
	@include media-min-w(768px) {
		margin-left: 0px;
	}
	@include media-min-w(900px) {
		margin-left: 65px;
	}
	@include media-min-w(1470px) {
		margin-left: 0px;
	}

	&.disabled {
		opacity: 0.4;
	}

	&:last-of-type {
		border-bottom: 0;
	}

	&.linked {
		.time-wrapper {
			&::before {
				background-color: $color-primary;
			}
		}
	}

	&.unlinked {
		.time-wrapper {
			&::before {
				background-color: $color-secondary;
			}
		}
	}

	&.is-running {
		.time-wrapper {
			.time-in-hours {
				.is-running-icon {
					opacity: 1;
				}
			}
		}
	}

	.time-wrapper {
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		text-align: left;
		width: 75px;
		padding: 15px 0px 15px 18px;
		box-sizing: border-box;
		@include transition(width 0.3s);

		@include media-min-w(567px) {
			width: 100px;
		}

		&::before {
			display: block;
			content: "";
			width: 5px;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			border-radius: 10px;
		}

		.start-end-time {
			@include font-size(15);
			font-weight: 500;
			margin-bottom: 4px;
			@include transition(font-size 0.3s);
			@include media-min-w(567px) {
				@include font-size(16);
			}
			span {
				display: block;
			}
		}

		.time-in-hours {
			.time-value {
				display: inline-block;
				position: relative;
				@include font-size(22);
				line-height: 1;
				font-weight: 700;
				color: rgba($color-text, 0.6);
				.time-unit-indicator {
					font-weight: 400;
					margin-left: 2px;
					@include font-size(18);
				}
			}
			.is-running-icon {
				display: inline-block;
				position: relative;
				opacity: 0;
				width: 20px;
				height: 16px;
				background-size: contain;
				background-repeat: no-repeat;
				background-position: center;
				background-image: url("../../../Components/assets/svg/task-icon-running-time-dark-purple.svg");
			}
		}
	}

	button.time-entry-context-menu {
		width: 50px;
		height: 50px;
		margin-left: 15px;
		padding: 0;
		@include font-size(28);
	}
}
