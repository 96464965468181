@import "../../../GlobalUtils/global-utils.scss";

.tab-menu {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	height: 60px;
	border-top: 1px solid rgba(255, 255, 255, 0.2);
	background: $color-text;
	position: fixed;
	bottom: 0;
	width: 100%;
	z-index: 99;

	@media screen and (min-width: 768px) {
		position: relative;
		bottom: auto;
		grid-row-start: 3;
		grid-row-end: 4;
	}
}
