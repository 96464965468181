@import "../../GlobalUtils/global-utils.scss";

.modal.timeline-modal {
	.modal-main-wrapper {
		@media screen and (min-width: 480px) {
			top: 50%;
		}
	}
	.close-button {
		@media screen and (min-width: 480px) {
			top: 15px;
			right: 25px;
		}
	}
}

#timeline-editor-wrapper {
	display: flex;
	flex-direction: column;
	width: calc(100vw - 30px);
	height: calc(100vh - 70px);
	overflow: hidden;
	background: $color-text;
	align-content: center;
	justify-content: center;
	max-width: 1080px;
	max-height: 1200px;
	border-radius: 6px;
	@media screen and (min-width: 480px) {
		height: calc(100vh - 30px);
	}

	.timeline-editor-container {
		display: flex;
		flex-direction: column;
		position: relative;
		width: 100%;
		height: 100%;
		margin: auto;
	}
	button {
		&.pill.sz-2 {
			.icon {
				margin: 0; // Override global styles
				width: 20px;
				height: 20px;
				top: auto;
				background-size: 20px;
			}
		}
		.label {
			margin-left: 12px;
			margin-right: 4px;
		}
	}
}

.timeline-header-wrapper {
	display: flex;
	position: relative;
	flex-direction: row;
	padding: 10px 8px;
	z-index: 2;
	box-shadow: 4px 0px 15px 6px rgba($color-text, 0.3);
	background: $color-text;
	@include media-min-w(640px) {
		padding: 10px 15px;
	}
}

.timeline-content-wrapper {
	display: block;
	position: relative;
	width: 100%;
	height: 100%;
	overflow-y: auto;
	overflow-x: hidden;
	background-color: $color-mid-purple;
	flex: 1 1 auto;
	z-index: 1;
	margin-bottom: 60px;
	transition: margin-bottom 0.3s;
	@include media-min-w(640px) {
		margin-bottom: 70px;
	}
	@include media-min-w(980px) {
		margin-bottom: 0;
	}

	.timeline-content-container {
		height: 100%;
		position: relative;
		display: block;
		@include transition(width 0.3s);
	}
}

.timeline-footer-wrapper {
	pointer-events: none;
	width: 100%;
	position: absolute;
	transition: all 0.3s;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: row;
	bottom: 0;
	height: 60px;
	overflow: hidden;
	z-index: 2;
	padding: 10px 15px;
	@include media-min-w(640px) {
		padding: 15px;
		height: 70px;
	}
	@include media-min-w(980px) {
		width: calc(100% - 15px);
		left: 0;
		right: 15px;
		height: auto;
		padding: 15px 25px 15px 15px;
	}
	* {
		pointer-events: initial;
	}
}

.timeline-zoom-controller-wrapper {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	position: relative;
	button {
		width: 40px;
		height: 40px;
		padding: 0;
		flex-shrink: 0;
		flex-grow: 0;
		background-size: 16px;
		background-color: transparent;
		@extend %single-icon;
		@include interactive-states() {
			background-color: $color-primary;
		}
		@include media-min-w(640px) {
			background-size: 20px;
		}
		&.zoom-in {
			background-image: url("../../Components/assets/svg/plus-blue.svg");
			@include interactive-states() {
				background-image: url("../../Components/assets/svg/plus-dark-purple.svg");
			}
		}
		&.zoom-out {
			background-image: url("../../Components/assets/svg/minus-blue.svg");
			@include interactive-states() {
				background-image: url("../../Components/assets/svg/minus-dark-purple.svg");
			}
		}
		&.edit-mode {
			opacity: 0.2;
		}
	}
	.zoom-scale {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		font-family: $font-display;
		color: $color-text-contrast;
		text-align: center;
		@include font-size(16);
		min-width: 40px;
		margin: 0 8px;

		@include media-min-w(640px) {
			@include font-size(18);
			min-width: 50px;
			margin: 0 15px;
		}
	}
}

.timeline-date-calander {
	position: relative;
	flex: 1 1 auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding-right: 15px;
	transition: all 0.3s;

	@include media-min-w(480px) {
		padding-right: 160px;
	}
	h2 {
		@include font-size(19);
		text-align: right;
		width: 100%;
		color: $color-text-contrast;
		font-weight: 700;
		transition: all 0.3s;
		@include media-min-w(480px) {
			@include font-size(21);
			text-align: center;
		}
		@include media-min-w(640px) {
			@include font-size(24);
			font-weight: 500;
		}
	}
}

.timeline-undoRedo-controller-wrapper {
	display: flex;
	flex-direction: row;
	position: relative;
	justify-content: flex-start;
	align-items: center;

	button {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		position: relative;
		background-color: transparent;
		text-align: left;
		font-size: 15pt;
		color: $color-text-contrast;
		width: 40px;
		height: 40px;
		background-position: center;
		background-repeat: no-repeat;
		left: 0;
		padding: 0;
		border: 1px solid transparent;
		@include interactive-states() {
			background-color: $color-primary;
			.label {
				color: $color-text;
			}
		}
		@include media-min-w(480px) {
			padding: 0 18px;
			background-color: $color-mid-purple;
			width: auto;
			border: 1px solid $color-primary;
		}

		.icon {
			display: inline-block;
			transition: all 0.3s;
		}
		.label {
			display: none;
			color: $color-text-contrast;
			transition: all 0.3s;

			@include media-min-w(480px) {
				display: inline-block;
			}
		}

		&.undo {
			margin-right: 10px;
			.icon {
				background-image: url("../../Components/assets/svg/undo-blue-outline.svg");
			}
			@include interactive-states() {
				.icon {
					background-image: url("../../Components/assets/svg/undo-dark-purple-outline.svg");
				}
			}
			@include media-min-w(380px) {
				margin-right: 15px;
			}
		}
		&.redo {
			.icon {
				background-image: url("../../Components/assets/svg/redo-blue-outline.svg");
			}
			@include interactive-states() {
				.icon {
					background-image: url("../../Components/assets/svg/redo-dark-purple-outline.svg");
				}
			}
		}
		&:disabled,
		&[disabled] {
			@include media-min-w(480px) {
				color: $color-text-contrast;
				background-color: transparent;
				border: 1px solid $color-text-contrast;
				@include interactive-states() {
					background-color: transparent;
					color: $color-text-contrast;
					.label {
						color: $color-text-contrast;
					}
				}
			}
			&.undo {
				.icon {
					background-image: url("../../Components/assets/svg/undo-white-outline.svg");
				}
				@include interactive-states() {
					background-color: transparent;
				}
			}
			&.redo {
				.icon {
					background-image: url("../../Components/assets/svg/redo-white-outline.svg");
				}
				@include interactive-states() {
					background-color: transparent;
				}
			}
		}
	}
}

.save-edit-mode-button {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: row;
	border-radius: 20px;
	background-color: $color-tertiary;
	color: $color-tertiary-contrast-dark;
	@include interactive-states() {
		background-color: darken($color-tertiary, 6%);
	}

	.icon {
		background-image: url("../../Components/assets/svg/tick-white-outline.svg");
	}
	span {
		position: relative;
	}

	&:disabled,
	&[disabled] {
		background-color: $color-text-contrast;
		.icon {
			background-image: url("../../Components/assets/svg/tick-dark-purple-outline.svg");
		}
		@include interactive-states() {
			background-color: $color-text-contrast;
		}
	}
}
