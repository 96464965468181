@import "../../../GlobalUtils/global-utils.scss";

.overlaying-button {
	position: relative;
	height: 100%;
	width: 100%;
	z-index: 10;
	margin-left: -20px;
}

.menu-button-container {
	display: block;
	position: relative;
	height: 40px;
	z-index: 100;

	.menu-button {
		width: 40px;
		height: 40px;
		position: relative;
		@include transition(all 0.3s);
		overflow: hidden;
		padding: 0;

		.menu-icon-bar {
			width: 24px;
			height: 2px;
			display: block;
			position: absolute;
			@include border-radius(2px);
			@include transition(all 0.3s);
			cursor: pointer;
		}

		&.open-menu {
			.menu-icon-bar {
				left: 8px;
				&:nth-of-type(1) {
					top: 10px;
				}
				&:nth-of-type(2) {
					top: 19px;
				}
				&:nth-of-type(3) {
					bottom: 10px;
					width: 12px;
				}
			}
			@include interactive-states() {
				.menu-icon-bar {
					&:nth-of-type(3) {
						width: 24px;
					}
				}
			}
		}

		&.close-menu {
			@include transform(rotate(0deg));
			.menu-icon-bar {
				left: 8px;
				width: 24px;
				&:nth-of-type(1) {
					top: 19px;
					@include transform(rotate(45deg));
				}
				&:nth-of-type(2) {
					top: 19px;
					@include transform(rotate(-45deg));
				}
			}
			@include interactive-states() {
				@include transform(rotate(180deg));
			}
		}
	}

	&.dashboard-button {
		.menu-button {
			.menu-icon-bar {
				background: $color-text-contrast;
			}
		}
	}

	&.timeline-button {
		.menu-button {
			.menu-icon-bar {
				background: $color-text;
			}
			&.close-menu {
				.menu-icon-bar {
					background: $color-text-contrast;
				}
			}
		}
	}
}
