@import "../../../GlobalUtils/global-utils.scss";

.comments-summary {
	position: relative;

	.comment-time-label {
		font-family: $font-display;
		color: $color-text-contrast;
	}

	.time-entry-comment {
		color: $color-text-contrast;
	}
}
