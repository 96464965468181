.date-separator-wrapper,
.group-start-time-wrapper {
	display: block;
	position: relative;
	width: 100%;
	height: 1px;
	margin: 30px auto 20px auto;
	background-color: rgba($color-text, 0.15);
	flex: 0 0 1;
	.date-separator,
	.group-start-time {
		@include font-size(16);
		color: rgba($color-text, 0.6);
		padding: 2px 8px;
		background-color: $color-text-contrast;
		position: absolute;
		left: 50%;
		top: 50%;
		line-height: 1;
		transform: translate(-50%, calc(-50% - 2px));
	}
}

// .group-start-time-wrapper {
//   display: block;
//   position: relative;
//   width: 100%;
//   height: 1px;
//   margin: 30px auto 15px auto;
//   background-color: rgba($color-text, 0.15);
//   flex: 0 0 100%;
//   height: 1px;
//   .group-start-time {
//     @include font-size(11);
//     color: rgba($color-text, 0.4);
//     padding: 2px 5px;
//     background-color: $color-text-contrast;
//     position: absolute;
//     left: 50%;
//     top: 50%;
//     @include transform(translate(-50%, -50%));
//   }
// }
