@import "../GlobalUtils/global-utils.scss";

html,
body {
	display: block;
	width: 100%;
	height: 100%;
}

main {
	// display: grid;
	// grid-template-columns: 1fr;
	// grid-template-rows: 85px 1fr 1fr auto;
	display: flex;
	flex-direction: column;
	overflow: auto !important;
	height: 100vh;
	width: 100%;
	background: linear-gradient(to bottom right, rgb(50, 30, 60), rgb(60, 30, 50));

	@include media-min-w(768px) {
		display: grid;
		grid-template-columns: 1fr auto;
		grid-template-rows: 85px 1fr auto;
		overflow: auto !important;
		height: 100vh;
	}

	.overflow-y-scroll {
		overflow-y: auto;
		overflow-y: overlay;
	}
}

main.white-bg {
	background: $color-text-contrast;
}

main.loading-spinner-screen {
	display: flex;
	justify-content: center;
	color: $color-text-contrast;

	.loading-spinner-content {
		display: grid;
		justify-items: center;

		.loading-spinner-message {
			margin-top: 15px;
		}
	}
}

header {
	&.header-wrapper {
		&.full-width {
			grid-column-end: 3 !important;
			&.logo-only {
				padding: 15px;
				@include media-min-w(768px) {
					padding: 30px;
				}

				.logo-container {
					max-width: 220px;
				}
			}
		}
	}
}

.hidden {
	display: none;
	visibility: hidden;
}

.header-wrapper {
	display: flex;
	flex-direction: row;
	justify-content: right;
	align-items: center;
	overflow: hidden;
	flex-shrink: 0;
	padding: 15px 15px 15px 8px;
	height: 60px;
	@include transition(all 0.3s);
	@include media-min-w(480px) {
		padding: 15px;
	}
	@media screen and (min-width: 576px) and (min-height: 768px) {
		height: 85px;
	}
	@include media-min-w(768px) {
		grid-row-start: 1;
		grid-row-end: 2;
		grid-column-start: 1;
		grid-column-end: 2;
		padding: 30px 15px 30px 10px;
	}

	@include media-min-w(1080px) {
		padding: 15px 30px;
	}

	button {
		flex-shrink: 0;
		flex-grow: 0;
		background-size: 25px;
		padding: 0;
		height: 40px;
		width: 40px;
		@include interactive-states() {
			background-size: 28px;
		}
		&.disabled,
		&:disabled {
			background-size: 25px;
			@include interactive-states() {
				background-size: 25px;
			}
		}
	}

	.logo-container {
		position: relative;
		flex: 0 0 auto;
		background-image: url("../Components/assets/svg/chronometric-logo-horizontal.svg");
		background-size: contain;
		background-position: center;
		background-repeat: no-repeat;
		height: 40px;
		width: 100%;
		max-width: 175px;
		margin: 0 auto;
		transition: max-width 0.3s;
		cursor: pointer;
		@include media-min-w(480px) {
			max-width: 225px;
		}
		@include media-min-w(1080px) {
			max-width: 250px;
		}
	}
}

#dashboard-view-header {
	button {
		&.open-tasklist {
			background-image: url("../Components/assets/svg/search-white-outline.svg");
		}
	}
}

// MOVE STYLES INTO TASK DETAILS / COMMENTS COMPONENT

main.running-time-not-linked {
	aside {
		&.task-comments-panel-wrapper {
			.task-details-panel {
				border-bottom: 2px solid $color-text;
				button.select-task {
					background-color: $color-mid-purple;
					color: $color-text-contrast;
					width: 100%;
					height: 100%;
					@include font-size(22);
					text-align: center;
					.icon {
						display: block;
						background-image: url("./assets/svg/link-40-orange.svg");
						background-repeat: no-repeat;
						background-size: contain;
						width: 50px;
						height: 50px;
						background-position: center;
						margin: 0 auto 15px auto;
					}
					@include interactive-states() {
						background-color: $color-text;
					}
				}
				.task-details-wrapper {
					display: none;
				}
			}
		}
	}
}

aside {
	&.task-comments-panel-wrapper {
		width: $aside-width-mobile;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		color: $color-text-contrast;
		background-color: $color-text;
		flex: 1 0 320px;
		padding-bottom: 60px;
		@include media-min-w(768px) {
			padding-bottom: 0px;
			grid-row-start: 1;
			grid-row-end: 5;
			grid-column-start: 2;
			grid-column-end: 3;
			width: $aside-width-tablet;
		}
		@include media-min-w(1080px) {
			width: $aside-width-desktop;
		}
	}
}

// From CommentsViewHeader.css
#comments-view-header,
#export-view-header {
	background: $color-text-contrast;
	box-shadow: 0 0 10px 4px rgba($color-text, 0.15);

	//   @include media-min-w(640px) {
	//     padding: 15px 30px;
	//   }

	&.header-wrapper {
		.logo-container {
			display: none;
			background-image: url("./assets/svg/chronometric-logo-horizontal-dark.svg");
			@include media-min-w(576px) {
				display: block;
				max-width: 175px;
			}
			@include media-min-w(768px) {
				display: none;
			}
			@include media-min-w(1080px) {
				display: block;
				max-width: 175px;
			}
			@include media-min-w(1200px) {
				max-width: 250px;
			}
		}
	}

	button {
		&.open-tasklist {
			background-image: url("./assets/svg/search-blue-outline.svg");
			margin-left: 0;

			@include media-min-w(400px) {
				margin-left: 55px;
			}
			@include media-min-w(576px) {
				margin-left: 15px;
			}
			@include media-min-w(768px) {
				margin-left: 0;
			}
			@include media-min-w(850px) {
				margin-left: 55px;
			}
			@include media-min-w(1080px) {
				margin-left: 15px;
			}
			@include media-min-w(1200px) {
				margin-left: 30px;
			}
		}
		&.open-timeline-editor {
			width: 40px;
			height: 40px;
			margin-left: 10px;
			margin-right: 0;
			background-image: url("./assets/svg/pencil-blue-outline.svg");
			@include media-min-w(480px) {
				margin-left: 15px;
			}
			@include media-min-w(576px) {
				margin-left: 30px;
			}
			@include media-min-w(768px) {
				margin-left: 15px;
			}
			@include media-min-w(1080px) {
				margin-left: 30px;
			}
			@include media-min-w(1470px) {
				margin-right: 135px;
			}
		}
	}
}
